import React from 'react'
import  image from '../assets/img/HEADER.jpg'

const heroStyle = {
    width: '100%',
}

const HeroContent = () => {
    return (
        <div className="content-hero" >
            <img src={image} style={heroStyle} alt="hero"/>
        </div>
    )
}

export default HeroContent