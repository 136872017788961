import './App.css';
import MainContent from './components/MainContent_BIH'
import Calendar from './components/Calendar_BIH'
import HeroContent from './components/HeroContent_BIH'

function App() {
  return (
    <>
      <div className="main-content pb-0">
        <div className="container mx-auto max-w-5xl bg-transparent">
          <HeroContent />
        </div>
        <div className="container mx-auto max-w-5xl px-0 lg:px-0 py-0 text-center">
          <MainContent />
        </div>
        <div className="container mx-auto max-w-5xl px-2 pt-7 pb-40 md:px-0 lg:pt-10 bg-white text-center form-bg">
          <Calendar />
          {/*<div className="flex justify-center mt-0 lg:-mt-8 relative z-1">
            <img className="w-full" src={bottomImage} alt="game prizes" />
          </div>*/}
        </div>
      </div>
    </>
  );
}

export default App;
