import React from 'react'
import mainContentPrizes from '../assets/img/creative-puding-nagrade.jpg'

const MainContent = () => {

  return (
    <div>
      <article className="mainContent pb-0 bg-white">
        <div className="px-2 lg:px-12">
          <h1 className="font-bold text-2xl sm:text-4xl lg:text-5xl mb-8 lg:mb-10">Awaken your creativity</h1>
          <h2 className="font-bold text-xl sm:text-2xl lg:text-3xl max-w-[60ch] mx-auto mb-8 lg:mb-10">Sudjelujte u programu lojalnosti “Creative puding” u kojem imate priliku osvojiti vrijedne nagrade!</h2>
          <p>Probudite svoju kreativnost uz Dr. Oetker jer možete osvojiti vrijedne nagrade.</p>
          <br />
          <p>Program lojalnosti traje od 1.11 – 30.11.2024. godine, a imate priliku osvojiti:</p>
          <p>7x BOSCH MOM uređaj, 50x poklon paket Dr.Oetker proizvoda</p>
          <br />
          <div className="max-w-[75ch] m-auto">
          <p><strong>Kako sudjelovati?</strong></p>
          <p>Slijedite par jednostavnih koraka jer nagrada može biti vaša!</p>
          <p>1. Kupite dva različita Creative pudinga na istom računu u bilo kojoj Bingo prodavnici na području Bosne i Hercegovine.</p>
          <p>2. Priložite fotografiju računa i popunite polja obrasca za prijavu koji se nalazi u nastavku.</p>
          <p>3. Odgovorite na kreativno pitanje. </p>
          <br />
          <p>Sretno!</p>
          </div>
          <br />
          <br />
        </div>
      </article>
      <div className="flex justify-center">
        <img className="w-full" src={mainContentPrizes} alt="game prizes" />
      </div>
    </div>
  )

}

export default MainContent